import React from 'react'
import Layout from "../components/layout"
import { graphql } from "gatsby"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Img from 'gatsby-image';
import bootstrap from "../components/bootstrap.module.css"
import FormatDate from "../utils/format-date.js"
import Tags from "../components/tags.js"
import Excerpt from "../components/excerpt.js"
import PostThumbnail from "../components/thumbnail.js"
import InfiniteScroll from 'react-infinite-scroll-component';
import SEO from "../components/seo"
import FilterPost from '../components/filter-post';

export default class Infinite extends React.Component {

	constructor(props) {
		super(props);
		
		this.author = props.data.author
		this.posts = props.data.allMysqlPost.edges
		this.perPage = 10
		this.startPage = 1
		this.seo = props.data.seo

		this.state = { 
			currentPage: 1,
			currentList: this.posts.slice(0, this.perPage)
		};

		this.fetchData = this.fetchData.bind(this); 
	}

	render() {

		const pageContext = this.props.pageContext

		const numPages = pageContext.numPages;
		const perPage = this.perPage
		const author = this.author

		const { currentPage, currentList } = this.state
		const pagesRange = []
		for(let i = currentPage-5 > 1 ? currentPage-5 : 1; i <= (currentPage+5 > numPages ? numPages : currentPage+5); i++) pagesRange.push(i)

		return (

			<Layout>

				<SEO title={author.display_name} description={this.seo.description} canonical={this.seo.canonical?this.seo.canonical:'https://fintek.pl/redaktor/'+this.author.user_nicename+'/'} robots={'index, follow'} />  


				<div className={[bootstrap.row,bootstrap.mb5].join(' ')}>

					<div className={bootstrap.colMd3}>
					{author.mysqlImage && <Img fluid={author.mysqlImage.childImageSharp.fluid} />}
					</div>

					<div className={bootstrap.colMd9}>
						<h1>{author.display_name}</h1> 
						<div className={bootstrap.mb3}>{author.description}</div>
					</div>
				</div>

				<hr className={bootstrap.mb4} />  

				<p className={[bootstrap.h3,'h3',bootstrap.mb5].join(' ')}>Artykuły autora:</p>

				<InfiniteScroll
				dataLength={currentPage*perPage}
				next={this.fetchData}
				hasMore={(currentPage)*perPage<this.posts.length}
				loader={<div className={`spinner `+bootstrap.textCenter}><span></span><span></span><span></span><span></span></div>}
				>
					{currentList.map(({ node }, i) => (
						<FilterPost key={node.id} index={i} postname={node.post_name}>
							<div className={bootstrap.overflowHidden}>
								<div className={bootstrap.mb3 + ' ' + bootstrap.row}> 
									<div className={bootstrap.colXl4+' '+bootstrap.colMd5}>
										<AniLink fade to={`/${node.post_name}/`} className={bootstrap.dBlock+' '+bootstrap.mb3}><PostThumbnail image={node.mysqlImage} /></AniLink>
									</div>    
									<div className={bootstrap.colXl8+' '+bootstrap.colMd7}>
										<AniLink fade to={`/${node.post_name}/`} className={bootstrap.mb1 + ' h3 ' + bootstrap.h3 + ' ' + bootstrap.dBlock}>{node.post_title}</AniLink>
										<div className={bootstrap.small + ' meta ' + bootstrap.my2}>
											{FormatDate(node.post_date)}
										</div>
										
										<Excerpt node={node} />
										<Tags tags={node.postterms} />
									</div>
								</div>
							</div>
						</FilterPost>
					))}   	

				</InfiniteScroll>

			</Layout>

		)


	}


	fetchData() {

		const { currentPage } = this.state

		this.setState({ 
			currentPage: currentPage+1,
			currentList: [...this.posts.slice(0, (currentPage+this.startPage)*this.perPage)]
		})
			
	}


}

export const query = graphql`
query ($id: Int!) {
	allMysqlPost(limit: 100, sort: {order: DESC, fields: post_date}, filter: {author: {ID: {eq: $id}}, nofollow: {ne: 1}, indexables: {elemMatch: {is_robots_noindex: {nin: [1]}}}})  {
		totalCount
		edges {
			node {
				post_name
				post_title
				post_excerpt
				lead
				post_date
				postterms {
					name
					slug
					parent_slug
					parent_name
				}   			  
				mysqlImage {
					childImageSharp {
						fluid(maxWidth: 400, maxHeight: 200) {
						...GatsbyImageSharpFluid_withWebp
						}
					}
				}    
			}            
		}
	}
	author: mysqlAuthor(ID: {eq: $id}) {
		ID
		display_name
		author_image
		user_email
		description
		user_nicename
		mysqlImage {
			childImageSharp {
				fluid(maxHeight: 300) {
				  ...GatsbyImageSharpFluid_withWebp 
				}
			  }
			}
	  }   
	  seo: mysqlIndexable(object_type: {eq: "user"}, object_id: {eq: $id}) {
		title
		description
		object_id
		object_type
		permalink
		breadcrumb_title
		canonical
	  }		  
}
`
