import React from 'react';
import { useStaticQuery, graphql } from 'gatsby'

const FilterPost = ({ children, postname }) => {
  const { nofollow, noindex } = useStaticQuery(graphql`
    query {
      nofollow: allMysqlPost(filter: {nofollow: {eq: 1}}) {
        values: distinct(field: post_name)
      }
      noindex: allMysqlPost(filter: {indexables: {elemMatch: {is_robots_noindex: {in: [1]}}}}) {
        values: distinct(field: post_name)
      }
    }
  `);

  if (!noindex.values.includes(postname) && !nofollow.values.includes(postname)) {
    return <>{children}</>;
  }

  return null;
}

FilterPost.defaultProps = {
  index: 0,
  postname: '',
};

export default FilterPost;