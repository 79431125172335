import PropTypes from "prop-types"
import React from "react"
import bootstrap from "./bootstrap.module.css"

const Excerpt = ({ node, className }) => (

  <p className={[bootstrap.m0,className].join(' ')}>{node.lead || node.post_excerpt}</p>
)

Excerpt.propTypes = {
  tags: PropTypes.node,
  className: PropTypes.string
}

export default Excerpt
