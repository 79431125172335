import React from "react"
import Img from 'gatsby-image';

const PostThumbnail = ({ image, className }) => {
  
  if(image) return (  
    <Img fluid={image.childImageSharp.fluid} className={className} />
  ) 
  
  return null;

}

export default PostThumbnail
